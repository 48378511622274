<template>
    <modal-confirmar ref="modalAnular" icon="close" titulo="Anular crédito" mensaje="Se cancelará la deuda pendiente de este pedido." @guardar="anularPago">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 px-2">
                <div class="col f-15">
                    <p class="pl-2">Justificación</p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:300" name="justificación" class="d-flex flex-column">
                        <el-input v-model="form.comentario" type="textarea" placeholder="Digite un comentario" :rows="3" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal-confirmar>
</template>
<script>
import Creditos from '~/services/almacen/creditos'

export default {
    data(){
        return{
            form:{
                comentario: ''
            },
            tendero: 1
        }
    },
    methods: {
        toggle(form, tendero){
            this.tendero = tendero
            this.form = form
            this.$refs.modalAnular.toggle();
        },
        async anularPago(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campo obligatorio', 'warning')
                    return false
                }
                if(this.tendero===1){ // admin pedidos
                    var {data} = await Creditos.anularPagoAdminPedidos(this.form)
                }else{// desde tendero
                    var {data} = await Creditos.anularPagoClientePedidos(this.form)
                }
                this.notificacion('Mensaje', 'Crédito anulado', 'success')
                // this.$store.dispatch('almacen/credito/action_listar_cuotas') // lo quite pq traia mal en tendero
                this.$refs.modalAnular.toggle();
                this.$emit('anular')

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
